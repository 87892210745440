@import "_media.scss";

* {
	box-sizing: border-box;
}

html {
	font-size: 16px;

	@media (max-width: $desktop-lg) {
    font-size: 15px;
	}

	@media (max-width: $desktop) {
    font-size: 14px;
	}

	@media (max-width: $tablets-lg) {
    font-size: 16px;
	}

	@media (max-width: $phones-sm) {
    font-size: 14px;
	}
}

p {
	margin: 0;
}

ol, ul {
	margin: 0;
	padding: 0;
}

button {
	cursor: pointer;
}

button,
a,
input,
textarea {
	&:focus {
		outline: 2px solid $color1;
		outline-offset: rem(1);
	}
}

html,
body {
  max-width: 100%;
	height: 100%;
	background: $wrapper-bg;
}

body {
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
}

ul, li {
	list-style: none;
	margin: 0;
	padding: 0;
}

img {
	display: block;
}

a {
	text-decoration: none;
}
// footer down start
.header,
.footer {
  flex: 0 0 auto;
}

.main {
  flex: 1 0 auto;
	position: relative;
	background: $wrapper-bg;
	padding: rem(24) 0 0;
}

.wrapper {
	height: 100%;
  display: flex;
	flex-direction: column;
	position: relative;
	padding-top: rem(80 + 16 + 64);

	@include tablets-lg() {
		padding-top: rem(80 + 8 + 64);
	}

	@include tablets() {
		padding-top: rem(56 + 128);
	}
}
// footer down end

.container {
	width: 100%;
	max-width: rem(1706);
	padding: 0 rem(20);
	margin: 0 auto;

	@include tablets-lg() {
		max-width: rem(885);
	}

	@include tablets() {
		padding: 0 rem(24);
	}

	@include phones-lg() {
		padding: 0 rem(16);
	}
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}