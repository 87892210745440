$browser-context: 16; // Default

$desktop-lg: 1440px;
$desktop: 1200px;
$desktop-sm: 992px;

$tablets-lg: 973px;
$tablets: 760px;
$tablets-sm: 600px;

$phones-lg: 576px;
$phones: 375px;
$phones-sm: 320px;

$header-h: 80;
$header-h-m: 56;

//colors
$white: #ffffff;
$black: #000000;
$red: #e40b03;
$error: #E03C25;;
$primary-color: #2263FB;
$primary-color-hover: #0849e1;
$primary-color-active: #043EC5;

$BlackRussian: #19191D;
$SlateBlue: #754BCD;
$DeepLilac: #945EBE;
$Fuchsia: #B960AE;
$Mulberry: #C7528E;
$wrapper-bg: $BlackRussian;

$color1: #4B69FF;
$color2: #EB4B4B;
$color3: #8847FF;
$color4: #D32CE6;
$color5: #5E98D9;
$color6: #B0C3D9;
$color7: #FFD700;

$milspec: $color1;
$covert: $color2;
$restricted: $color3;
$classified: $color4;
$uncommon: $color6;
$rare: $color7;

// durations
$tr-duration: 0.3s;

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@function lh($lh, $fs) {
  @return #{$lh/$fs};
}

@mixin bg($url) {
  background: url($url) no-repeat 50%/100%;
}

@mixin fz($fz, $lh) {
  font-size: rem($fz);
  line-height: lh($lh, $fz);
}

@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
    object-fit: $fit;
  @if $position {
    -o-object-position: $position;
      object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

@mixin text_clamp($mxHeight, $rows) {
  max-height: rem($mxHeight);
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: $rows;
}

@function str-replace($string, $search, $replace: '') {
  $string: quote($string);
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return unquote($string);
}

@mixin getBorder($border_image: null) {
  @if($border_image) {
    border: rem(3) solid;
    border-image-slice: 1;
    border-image-source: $border_image;
  } @else {
    border: rem(3) solid transparent;
  }
}

@mixin btn-generate($conf) {
  $class: map-get($conf, class);
  $background: map-get($conf, background);
  $border: map-get($conf, border);
  $border_image: map-get($conf, border_image);

  .#{$class} {
    background: rgba($background, 0.05);

    &:hover {
      background: rgba($background, 0.1);
    }

    &:active {
      background: rgba($background, 0.07);
      transform: translate3d(0, rem(5), 0);
    }

    .btn-pay__inner {
      @include getBorder($border_image);
    }
  }
}

// IE10+ CSS styles go here
@mixin target-ie11 {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin target-edge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

@mixin target-safari {
  @media not all and (min-resolution: .001dpcm) {
    @content;
  }
}

@mixin text_gardient($gradient, $fallback: null) {
  background: linear-gradient(unquote($gradient));
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @if ($fallback) {
    @include target-ie11{
      color: $fallback;
      background: transparent;
    }
  }
}

//-------------- media --------------
@mixin desktop-lg {
  @media screen and (max-width: $desktop-lg) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: $desktop) {
    @content;
  }
}

@mixin desktop-sm {
  @media screen and (max-width: $desktop-sm) {
    @content;
  }
}

@mixin tablets-lg {
  @media screen and (max-width: $tablets-lg) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: $tablets) {
    @content;
  }
}

@mixin tablets-sm {
  @media screen and (max-width: $tablets-sm) {
    @content;
  }
}

@mixin phones-lg {
  @media screen and (max-width: $phones-lg) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: $phones) {
    @content;
  }
}

@mixin phones-sm {
  @media screen and (max-width: $phones-sm) {
    @content;
  }
}

@mixin custom($prop) {
  @media screen and (max-width: $prop) {
    @content;
  }
}