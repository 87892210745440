* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

@media (max-width: 1440px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 973px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 320px) {
  html {
    font-size: 14px;
  }
}

p {
  margin: 0;
}

ol, ul {
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

button:focus,
a:focus,
input:focus,
textarea:focus {
  outline: 2px solid #4B69FF;
  outline-offset: 0.0625rem;
}

html,
body {
  max-width: 100%;
  height: 100%;
  background: #19191D;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  display: block;
}

a {
  text-decoration: none;
}

.header,
.footer {
  flex: 0 0 auto;
}

.main {
  flex: 1 0 auto;
  position: relative;
  background: #19191D;
  padding: 1.5rem 0 0;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 10rem;
}

@media screen and (max-width: 973px) {
  .wrapper {
    padding-top: 9.5rem;
  }
}

@media screen and (max-width: 760px) {
  .wrapper {
    padding-top: 11.5rem;
  }
}

.container {
  width: 100%;
  max-width: 106.625rem;
  padding: 0 1.25rem;
  margin: 0 auto;
}

@media screen and (max-width: 973px) {
  .container {
    max-width: 55.3125rem;
  }
}

@media screen and (max-width: 760px) {
  .container {
    padding: 0 1.5rem;
  }
}

@media screen and (max-width: 576px) {
  .container {
    padding: 0 1rem;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
